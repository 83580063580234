.star {
    color: #f5de50;
    font-size: 3rem;
    position: absolute;
    animation-name: burst;
    animation-duration: 1000ms;
    /* animation-timing-function: ease-out; */
    animation-fill-mode: forwards;
    z-index: 100000;
    opacity: 0;
    pointer-events: none;
}


@keyframes burst {
        1% {
            opacity: 0;
        }

        5% {
            opacity: 1;
            transform: scale(1.5);
        }


        50% {
            transform: scale(1);
        }
    
    
          100% {
            opacity: 0.25;
            transform: scale(.1);
            transform: translate(var(--x), var(--y));
          }
}