.ringCentralFrame {
  margin-top: 10px;
  align-self: center;
  justify-self: center;
  min-height: 600px;
}

.ringCentralDiv {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  max-width: 450px;
  /* min-height: 600px; */
  height: fit-content;
  margin: auto;
  
}

/* .sc-ion-modal-md-h {
  --min-width: 400px;
  --width: 70%;
  --max-width: 800px;
  --min-height: 375px;
  --height: 80%;
  --max-height: 900px;
} */

.sc-ion-alert-md-h {
  --min-width: 300px;
  --max-width: 350px;
}

.inverted {
    background-image: url("./images/aluminum.jpg");
  --background: rgba(128, 128, 128, 0.791);
  color: white;
}

.woodBackground {
  background-image: url("./images/woodTexture.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  --background: rgba(233, 233, 233, 0.394);
}

.aluminumBackground {
  background-image: url("./images/aluminum.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  --background: rgba(255, 255, 255, 0.585);
}

.rotatedAluminumBackground {
  background-image: url("./images/aluminumRotated.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  --background: rgba(255, 255, 255, 0.585);

}

.carbonBackground {
  background-image: url("./images/carbonFiber.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  --background: rgba(255, 255, 255, 0.751);
  /* background-color: #dadada */
}

.bgImg {
  background-image: url("./images/backgroundTest.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  --background: rgba(255, 255, 255, 0.877);
}

.tgImg {
    background-image: url("./images/tgWall.jpg");
      background-repeat: repeat;
      background-size: contain;
      background-position: top;
      background-attachment: scroll;
      --background: rgba(255, 255, 255, 0.585);
}

.propertyImg {
  background-image: url("./images/tradesmanShop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top; 
  --background: rgba(255, 255, 255, 0.585);
}

.homeImg {
  background-image: url("./images/tradesmanShop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  --background: transparent;
}

.bgAdmin {
  background-image: url("./images/adminBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  --background: rgba(255, 255, 255, 0.877);

}

.taskViewModal {
  --min-width: 400px;
  --max-width: 800px;
  --min-height: 375px;
  --height: 80%;
  --max-height: 900px;
}

/* IONIC COLORS */

:root {
  /* PRIMARY */
  --ion-color-primary: #326a76;
  --ion-color-primary-rgb: 50, 106, 118;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2c5d68;
  --ion-color-primary-tint: #477984;

  /* SUCCESS */
  --ion-color-success: #adff2f;
  --ion-color-success-rgb: 173, 255, 47;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #98e029;
  --ion-color-success-tint: #b5ff44;

  /* WARNING */
  --ion-color-warning: #ffff7e;
  --ion-color-warning-rgb: 255, 255, 126;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0e06f;
  --ion-color-warning-tint: #ffff8b;

  /* DANGER */
  --ion-color-danger: #ff9381;
  --ion-color-danger-rgb: 255, 147, 129;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e08172;
  --ion-color-danger-tint: #ff9e8e;
}

/* LED LIGHTS
   These can be used anywhere in app!
*/
/* LED LIGHTS */


/* .led-box {
  height: 15px;
  width: 15px;
  margin: 10px 0;
  float: right;
} */

.led-red {

  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 16px;
  height: 16px;
  background-color: #F00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 5px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}

@-webkit-keyframes blinkRed {
  from {
    background-color: #F00;
  }

  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 5px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }

  to {
    background-color: #F00;
  }
}

@-moz-keyframes blinkRed {
  from {
    background-color: #F00;
  }

  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 5px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }

  to {
    background-color: #F00;
  }
}

@-ms-keyframes blinkRed {
  from {
    background-color: #F00;
  }

  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 5px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }

  to {
    background-color: #F00;
  }
}

@-o-keyframes blinkRed {
  from {
    background-color: #F00;
  }

  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 5px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }

  to {
    background-color: #F00;
  }
}

@keyframes blinkRed {
  from {
    background-color: #F00;
  }

  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 5px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }

  to {
    background-color: #F00;
  }
}

.led-yellow {
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 16px;
  height: 16px;
  background-color: #FF0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 5px, #FF0 0 2px 12px;
  -webkit-animation: blinkYellow 1s infinite;
  -moz-animation: blinkYellow 1s infinite;
  -ms-animation: blinkYellow 1s infinite;
  -o-animation: blinkYellow 1s infinite;
  animation: blinkYellow 1s infinite;
}

@-webkit-keyframes blinkYellow {
  from {
    background-color: #FF0;
  }

  50% {
    background-color: #AA0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 5px, #FF0 0 2px 0;
  }

  to {
    background-color: #FF0;
  }
}

@-moz-keyframes blinkYellow {
  from {
    background-color: #FF0;
  }

  50% {
    background-color: #AA0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 5px, #FF0 0 2px 0;
  }

  to {
    background-color: #FF0;
  }
}

@-ms-keyframes blinkYellow {
  from {
    background-color: #FF0;
  }

  50% {
    background-color: #AA0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 5px, #FF0 0 2px 0;
  }

  to {
    background-color: #FF0;
  }
}

@-o-keyframes blinkYellow {
  from {
    background-color: #FF0;
  }

  50% {
    background-color: #AA0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 5px, #FF0 0 2px 0;
  }

  to {
    background-color: #FF0;
  }
}

@keyframes blinkYellow {
  from {
    background-color: #FF0;
  }

  50% {
    background-color: #AA0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 5px, #FF0 0 2px 0;
  }

  to {
    background-color: #FF0;
  }
}

.led-green {
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 16px;
  height: 16px;
  background-color: #ABFF00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 5.5px, #89FF00 0 2px 12px;
}

.led-off {
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 16px;
  height: 16px;
  background-color: #4e7500;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #1520003c 0 -1px 5.5px, #182e0025 0 2px 12px;
}

.led-blue {
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 16px;
  height: 16px;
  background-color: #24E0FF;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 5px, #3F8CFF 0 2px 14px;
}